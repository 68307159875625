.detail-body {
    background-color: #f4f4f4;
    padding-bottom: 40px;
}

.detail-card {
    display: flex;
    padding-top: 30px;
}

.detail-card img {
    height: 70vh;
    width: 50%;
    z-index: 110;
}

.detail {
    padding: 0 35px;
    position: relative;
    background-color: #fff;
    height: 70vh;
    overflow: hidden;
}

.detail::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(35, 35, 154);
    opacity: 0.1;
    left: 0px;
    border-top-right-radius: 90%;
}

.detail::after {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: rgba(243, 70, 8, 0.8);
    opacity: 0.1;
    left: 0px;
    bottom: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 100%;
}

.detail * {
    z-index: 100;
    position: relative;
}

#by {
    color: orangered;
}
.detail h3 {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    color: #b70000;
}

.detail p:first-of-type {
    color: #145875;
}

.description {
    height: 100px;
    overflow: hidden;
    text-overflow: clip;
}

.edit-btn {
    border: none;
    border-radius: 5px;
    background-color: green;
    color: #fff;
    margin-top: 20px;
    padding: 5px 10px;
    width: 200px;
}

.edit-btn:hover {
    opacity: 0.75;
}