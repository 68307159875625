.hero {
    position: relative;
    height: 90vh;
}
.hero > img {
    position: absolute;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    object-position: bottom;
}

.hero>div {
    position: absolute;
    padding: 40px ;
    background: rgba( 255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.25 );
    border-top: 1px solid rgba( 255, 255, 255, 0.5 );
    border-left: 1px solid rgba( 255, 255, 255, 0.5 );
}
.hero>div a, .yellow-text {
    font-weight: 500;
    font-size: 1.5em;
    text-decoration: none;
}

.hero>div:first-of-type {
    top: 30%;
    right: 20%;
}
.hero>div:nth-of-type(2) {
    top: 30%;
    right: 50%;
}
.hero>div:last-of-type {
    top: 55%;
    right: 30%;
    z-index: 50;
}

.purple-text {
    color: purple;
}


.orange-text {
    color: orangered;
}

.red-text {
    color: red;
}

.yellow-text {
    color: yellow;
    cursor: pointer;
}
.yellow-text:hover {
    color: blue;
}

.dark-text {
    color: #000;
}

.green-text {
    color: green;
}