.search-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 80;
    background-color: rgba(0, 0, 0, 0.5);
}
#user-search-area {
    background-color: #f4f4f4;
    position: fixed;
    height: 50vh;
    width:80vw;
    padding-top: 15px;
    z-index: 1000;
    top: 15vh;
    left: 10vw;
    background: #fff;
    box-shadow: 4px 3px 13px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);

}
.search-modal>#user-search-area>.modal-header {
    justify-content: space-between;
    color: orangered;
}
#user-search {
    width: 60vw;
    height: 50px;
    border: 1px orangered solid;
    border-radius: 10px;
    margin-top: 50px;
    padding: 10px 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#user-search:focus {
    outline: 2px orangered solid;
    border: none;
}